import React from "react";
import LComponent from "./LogoComponent";
import Page from "./PageComponent";
function PreRelease() {
  return (
    <div >
    <Page/>
    </div>
  );
}

export default PreRelease;
