import React from 'react';
import { createRoot } from 'react-dom/client'; 
import App from './App'; // Ensure App is imported correctly

const root = createRoot(document.getElementById('root')); // Ensure 'root' is correct in your index.html
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
