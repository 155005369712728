import React from 'react';
import "./Css/PreRealse.css";
import AI1 from "./Images/AI1.png";
import LComponent from './LogoComponent';

 function Page(){
    return(
        <div>    <div className="PreReleaseContainer">
        <h1 class="HomeTopTitle">
          Empowering Your Self-Development Journey with Cutting-Edge AI
        </h1><br></br>
        <h3>WhiteList soon!</h3>
        <LComponent/>
        <h2>LearnReflect's</h2>
        <div className="LearnReflect">
          <p className="Headline-1">
            LearnReflect is a cutting-edge self-improvement platform that
            harnesses the power of AI<br /> To help - Assist & guide you to
            achieve personal growth.
          </p>
          <p className="Headline-2">
            build discipline & stay motivated.<br /> Our suite of AI-driven tools
            includes routine planners, personalized motivation strategies, and
            discipline-building techniques to help you stay focused on your goals.
          </p>
          <p className="Headline-3">
            In addition<br /> we offer advanced AI models for enhancing video and
            audio quality, making LearnReflect an all-in-one solution<br /> for
            anyone seeking to improve themselves and their productivity. <br />
            Whether you're aiming to boost your daily habits or <br /> achieve
            long-term success,<br /> LearnReflect is your guide to becoming the
            best version of yourself.
          </p>
          <img className="AI1" alt="AI4" src={AI1} />
        </div>
        </div>
        </div>
    );
}
export default Page;